import React from 'react';
import './HeadingDecorated.scss';

const HeadingDecorated = ({ children, className = '' }) => {
  return (
    <h2 className={`heading-decorated ${className}`}>
      <span>{children}</span>
    </h2>
  );
};

export default HeadingDecorated;
