import React from 'react';
import '../../css/about.scss';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';
import HeadingDecorated from '../../components/HeadingDecorated';
import TeamMember from '../../components/TeamMember';
import Seo from '../../components/Seo';
import arrowRightImg from '../../images/arrow-right.png';

export const LOCALIZED_PAGES = { pl: '/o-nas/zespol/', en: '/about-us/team/' };

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      zespol: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: { section: { eq: "zespol" } }
      ) {
        nodes {
          bio {
            data {
              bio
            }
          }
          email
          fullName
          jobTitle
          avatar {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout localizedPages={LOCALIZED_PAGES}>
      <Seo title="O nas | Zespół" />
      <div className="about">
        <section>
          <HeadingDecorated className="about__section-heading">
            Zespół
          </HeadingDecorated>
          <div className="about__section-content">
            {data.zespol.nodes.map((item) => (
              <TeamMember data={item} key={item.fullName} />
            ))}
          </div>
        </section>
        <Link
          to="/o-nas/zespol/wspolpracownicy/"
          className="about__footer-link about__footer-link--forward"
        >
          Współpracownicy <img src={arrowRightImg} alt="&rarr;" />
        </Link>
      </div>
    </Layout>
  );
};

export default AboutPage;
