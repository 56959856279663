import React from 'react';
import mailImg from '../images/mail.svg';

const TeamMember = ({ data }) => (
  <article className="about__person">
    <img
      src={data.avatar.localFile.url}
      className="accent-shadow about__picture"
      alt={data.fullName || data.localizations.data[0].attributes.fullName}
    />
    <div className="about__business-card">
      <h3 className="about__name">
        {data.fullName || data.localizations.data[0].attributes.fullName}
      </h3>
      <h4 className="about__title">
        {data.jobTitle || data.localizations.data[0].attributes.jobTitle}
      </h4>
    </div>
    <p className="about__bio">
      {data?.bio?.data?.bio || data.localizations.data[0].attributes.bio}
    </p>
    {data.email && (
      <a href={`mailto:${data.email}`} className="about__mail">
        <img src={mailImg} alt="e-mail" />
        {data.email}
      </a>
    )}
  </article>
);

export default TeamMember;
