import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../../../components/Layout';
import HeadingDecorated from '../../../components/HeadingDecorated';
import TeamMember from '../../../components/TeamMember';
import Seo from '../../../components/Seo';
import arrowRightImg from '../../../images/arrow-right.png';
import { LOCALIZED_PAGES } from '../../o-nas/zespol';
import '../../../css/about.scss';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      zespol: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: {
          localizations: {
            data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
          }
          section: { eq: "zespol" }
        }
      ) {
        nodes {
          localizations {
            data {
              attributes {
                bio
                fullName
                jobTitle
              }
            }
          }
          avatar {
            localFile {
              url
            }
          }
          email
        }
      }
    }
  `);

  return (
    <Layout locale="en" localizedPages={LOCALIZED_PAGES}>
      <Seo title="About us | Team" />
      <div className="about">
        <section>
          <HeadingDecorated className="about__section-heading">
            Team
          </HeadingDecorated>
          <div className="about__section-content">
            {data.zespol.nodes.map((item) => (
              <TeamMember
                data={item}
                key={item.localizations.data[0].attributes.fullName}
              />
            ))}
          </div>
        </section>
        <Link
          to="/en/about-us/team/associates/"
          className="about__footer-link about__footer-link--forward"
        >
          Fellows and Interns <img src={arrowRightImg} alt="&rarr;" />
        </Link>
      </div>
    </Layout>
  );
};

export default AboutPage;
